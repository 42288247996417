import { marketingoverviewApi } from 'api/marketing-overview/marketing';
import { AppDispatch } from 'store/store';
import {  loadDataRequest } from 'types/marketing/overview';

import { marketingloaddataSlice } from './slice';



  export const getCLientloadStateData =
  (params: loadDataRequest, onFailure: () => void) => async (dispatch: AppDispatch) => {
    dispatch(marketingloaddataSlice.actions.processGetOverviewData());

    try {
      const { data } = await marketingoverviewApi.getCLientloadStateData(params);

      dispatch(marketingloaddataSlice.actions.getloadDataSuccess(data.result));
    } catch {
      dispatch(marketingloaddataSlice.actions.getloadDataFailure());

      onFailure();
    }
  };

  //getCLientloadStateData