import axios from 'axios';
import qs from 'qs';

export const vennFlowApi = axios.create({
  baseURL: process.env.REACT_APP_VENN_FLOW_API_URL,
  paramsSerializer: params => qs.stringify(params, { arrayFormat: 'comma', encode: true }),
});

export const brainApiBase = axios.create({
  baseURL: process.env.REACT_APP_BRAIN_URL_BASE,
  paramsSerializer: params => qs.stringify(params, { arrayFormat: 'comma', encode: true }),
});

export const onboardingApi = axios.create({
  baseURL: process.env.REACT_APP_ONBOARDING_API_URL,
});

export const pyramidApiBase = axios.create({
  baseURL: process.env.REACT_APP_PYRAMID_URL,
});

export const pyramidApi2Token = axios.create({
  baseURL: process.env.REACT_APP_PYRAMID_API2_TOKEN,
});
