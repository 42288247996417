import {
  LABELED_Y_AXIS_WIDTH,
  LABELED_X_AXIS_HEIGHT,
  Y_AXIS_LABEL_OFFSET,
  X_AXIS_LABEL_OFFSET,
  LEGEND_HEIGHT,
} from 'constants/charts';

import { Box } from '@mui/material';
import uniq from 'lodash/uniq';
import {
  LineChart as RELineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Legend,
  Tooltip,
  Label,
} from 'recharts';
import { ChartAxisLabels, Dimensions } from 'types/common/charts';

import { mapLineChartData } from './helpers/adapters';
import { CustomTooltip } from '../custom-tooltip/custom-tooltip';
import { legendFormatter } from '../helpers/legend-formatter';
import { tickFormatter } from '../helpers/tick-formatter';
import { chartStyles } from '../styles';


const colors = ['#00B2FF', '#F7B500', '#6E759F', '#5569ff', '#57CA22', '#44DEC5', '#0A2435'];

interface Props<T> {
  data: T[];
  xDataKey: string;
  yDataKey: string | string[];
  groupBy: keyof T;
  hideLegend?: boolean;
  hideYAxis?: boolean;
  hideXAxis?: boolean;
  dashArray?: string[];
  labels?: ChartAxisLabels;
  dimensions: Dimensions;
  tooltipBeforeSymbol?: string;
  tooltipAfterSymbol?: string;
  linecolor?: string;
  formatDate?: boolean;
  customYAxisLabel?: string;
  strokeWidth?: number;
  showDate: boolean;
  legend?: string[];
}

const defaultColors = ['#00B2FF', '#F7B500', '#6E759F', '#dea300'];

export function LineChart<T extends Record<string, any>>({
  data,
  xDataKey,
  yDataKey,
  groupBy,
  hideLegend,
  hideYAxis,
  hideXAxis = true,
  dashArray = null,
  labels = {},
  dimensions,
  tooltipBeforeSymbol,
  tooltipAfterSymbol,
  linecolor,
  formatDate,
  customYAxisLabel: customYAxisLabel,
  strokeWidth = 4,
  showDate,
  legend,
}: Props<T>) {
  const domain = Array.isArray(yDataKey) ? yDataKey : uniq(data.map(d => d[groupBy]));

  const readyData = Array.isArray(yDataKey) ? data : mapLineChartData({ data, xDataKey, yDataKey, groupBy });
  const mapPayload = legend?.map((name, index) => ({
    value: name,
    color: defaultColors[index],
  }));

  return (
    <Box width={dimensions.width} height={dimensions.height} minHeight={dimensions.height}>
      <ResponsiveContainer width="100%">
        <RELineChart data={readyData}>
          <CartesianGrid stroke="#E2E7E7" strokeDasharray="5 10" vertical={false} />

          {!hideXAxis && (
            <XAxis
              dataKey={xDataKey}
              height={labels.x ? LABELED_X_AXIS_HEIGHT : undefined}
              tickLine={false}
              tickFormatter={formatDate && tickFormatter}
            >
              {labels.x && (
                <Label
                  value={labels.x}
                  position="insideBottom"
                  offset={X_AXIS_LABEL_OFFSET}
                  style={chartStyles.xAxisLabel}
                />
              )}
            </XAxis>
          )}

          {!hideYAxis && (
            <YAxis axisLine={false} width={labels.y ? LABELED_Y_AXIS_WIDTH : undefined} tickLine={false}>
              {labels.y && (
                <Label
                  value={labels.y}
                  position="insideLeft"
                  angle={-90}
                  offset={Y_AXIS_LABEL_OFFSET}
                  style={chartStyles.yAxisLabel}
                />
              )}
            </YAxis>
          )}

          <Tooltip
            isAnimationActive={false}
            content={props => (
              <CustomTooltip
                {...props}
                customYAxisLabel={customYAxisLabel}
                tooltipBeforeSymbol={tooltipBeforeSymbol}
                tooltipAfterSymbol={tooltipAfterSymbol}
                showDate={showDate}
                customPayload={mapPayload}
                label=""
              />
            )}
          />

          {!hideLegend && (
            <Legend
              payload={mapPayload}
              verticalAlign="top"
              align="center"
              height={LEGEND_HEIGHT}
              iconType="circle"
              formatter={legendFormatter()}
            />
          )}
          {domain.map((group, index) => (
            <Line
              strokeDasharray={dashArray && dashArray[index]}
              key={group}
              type="monotone"
              dataKey={group}
              stroke={linecolor ? linecolor : colors[index]}
              dot={false}
              strokeWidth={strokeWidth}
            />
          ))}
        </RELineChart>
      </ResponsiveContainer>
    </Box>
  );
}
