import numeral from 'numeral';

export const percentage = (partial: number, total: number) => (partial / total) * 100;

export const formatNumber = (value: number, fractionDigits = 2) =>
  numeral(value).format(`0,0[.]${new Array(fractionDigits).fill('0').join('')}`);

export const roundValue = (value: number, fractionDigits = 2) =>
  value ? (Number.isInteger(value) ? value : value.toFixed(fractionDigits)) : '0';

export const maxValue = (value: number, maxValue:number = 100) =>
{
  if(value > maxValue)
    return maxValue;
  else
    return value;
}

export const formatNumberscomma = (number: any) => {
  number = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return number;
};

export const formatIfNumber = (val:any) =>{
    if(!isNaN(val))
      return formatNumber(Number(val), 2);
    else
      return val;

};

export const currencyLabel =(currency:string) =>{
  switch(currency){
    case 'GBP':
        return '£';
    case 'USD':
        return '$';
    case 'EUR':
        return '€';
    default:
      return '£';
  }
}