import { Response } from 'types/api/api';
import { GetCurrentPyramidResult, Pyramid, PyramidMenuItems } from 'types/pyramid/pyramid';

import { pyramidApiBase, pyramidApi2Token } from '../api';

const headers = {
  'Content-Type': 'application/json',
  //paToken: pyramidApi2Token,
  // Pyramidembeddedauth:
  //   '7PGOcRP+wE1fhaiBnFO2RC0d31aMhJ4Ntz8DU98L0C6gLfPKqN+ZC3VAFvkEsDhC9C7r7pJ7HWGZ/DLq6J6PrMEYtCTK5NItTE4HZHXbuPxO3PMesMk94OzIMf2o0PI+Bi4DcZEPk6yB4hprTvz9qS8VGzaGY78yuKSUmYPkxEa69Tb2dpFc6U4F/fBPI3bwCmWC39/sNKKoG5aSa8cyhAzTi8p/dfYKSWgQC6rUFsBxhVdr5XASnTFkn9N/sNzcXPaV5UO11axb44rMg5Ad1LwT7IhQEg70E8NQmsh4r/pAr2w8BjLhS6yD/KkgBoSwruuwCwlHp6xiNqmlacT3X3HNoU76d+MoYG1WysANJCsB8lT/C4apzxtz+1lvekkmttEZf2sXDnvhSt8u+3SGvSM4oIm+FtxJhnWxRZPt+HwRG8KfCQE3V5rFk8u93RycUwG/KljVra1hJGUQbr+K4Wa2FSpjqxw6YEtT+zxG0YqHO1v86rqEf9gPsh98wJZs4PA3tE2NYX9kFKzm0W7i3KL+SFbAfqGjgqfpWqTAgfZtKK1tQ6zwyVwaSTxS8JZ7iAodbUCiNcAoMWx35sYcFpY1Mm0XiPm9H2K4AlzvXEs58CkQOgrdvFJEhuyO6s6XJ0EQ/Lj1f/aYslUWdTDkTFJgZDfPzJWPDIxBziiHxJ4=',
};

const getPyramidMenuItems = (folderId: string) =>
  pyramidApiBase.post<Response<PyramidMenuItems[]>>(
    'content/getFolderItems',
    {
      auth: `${process.env.REACT_APP_PYRAMID_API2_TOKEN}`,
      folderId: folderId,
    },
    { headers },
  );
//pyramidApiBase.post<Response<PyramidMenuItems[]>>('infraEntryPoint', payload, { headers });

export const pyramidApi = {
  getPyramidMenuItems,
};
