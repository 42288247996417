import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import DonutSmallTwoToneIcon from '@mui/icons-material/DonutSmallTwoTone';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import PsychologyIcon from '@mui/icons-material/Psychology';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import RemoveRedEyeTwoToneIcon from '@mui/icons-material/RemoveRedEyeTwoTone';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { routes } from 'router/routes';

export interface MenuItem {
  name: string;
  link: string;
  disable?: boolean;
  icon?: any;
  badge?: string;
  children?: MenuItem[];
  tooltip?: string; 
  videoUrl?: string;
  newTab? :boolean;
}

export interface MenuItemsProps {
  name: string;
  items: MenuItem[];
}

export const menuItems: MenuItemsProps[] = [
  {
    name: 'General',
    items: [
      {
        name: 'Business Overview',
        icon: RemoveRedEyeTwoToneIcon,
        link: routes.overview.url,
      },
      {
        name: 'E-commerce Sales',
        icon: ShoppingCartIcon,
        link: routes.shopifydashboard.url,
      },
      {
        name: 'Sales and Spend This Year',
        icon: QueryStatsIcon,
        link: routes.revenueSpend.url,
      },
      {
        name: 'Paid Marketing Performance',
        icon: ShowChartIcon,
        link: routes.paidMarketingPerformance.url,
      },
      {
        name: 'Advertising Platform Sales',
        icon: DonutSmallTwoToneIcon,
        link: routes.marketingAnalyzer.channel.url,
      },
      {
        name: 'Active Campaign Analytics',
        icon: DonutSmallTwoToneIcon,
        link: routes.marketingAnalyzer.campaign.url,
      },
      {
        name: 'Tiktok and Snapchat Performance',
        icon: QueryStatsIcon,
        link: routes.tiktokDashboard.url,
      },
      {
        name: 'Shopping Journey',
        icon: LocalMallIcon,
        link: routes.shoppingJourney.url,
      },
      {
        name: 'Customer Segmentation',
        icon: LocalMallIcon,
        link: routes.pyramid.url,
      },
      {
        name: 'Ad Spend Analysis',
        icon: LocalMallIcon,
        link: routes.multipyramid.url,
      },
      {
        name: 'Product Sales Analysis',
        icon: LocalMallIcon,
        link: routes.pyramidprodsalesanalysis.url,
      },
      {
        name: 'Analytic Content',
        icon: LocalMallIcon,
        link: routes.analyticcontent.url,
      },

      {
        name: 'Pyramid Widget',
        icon: LocalMallIcon,
        link: routes.pyramidwidget.url,
      },
      {
        name: 'Spend Manager',
        icon: LocalMallIcon,
        link: routes.pyramidspendmanager.url,
      },
      // {
      //   name: 'Overall Paid Marketing Performance Trend',
      //   icon: InsertChartIcon,
      //   link: routes.overallTrendPerformance.url,
      // },
      {
        name: 'Product Profitability',
        icon: DonutSmallTwoToneIcon,
        link: routes.productProfitability.url,
      },
      {
        name: 'BRAiN',
        icon: PsychologyIcon,
        link: routes.brain.url,
        videoUrl: 'https://www.youtube.com/embed/zKpDCNZixY4?si=jgweSLbly5ZP9fmS',
      },
      {
        name: 'New Analytics',
        icon: LocalMallIcon,
        link: 'https://172.167.251.96',
        newTab: true, // Add this property
      },
      {
        name: 'Attribution Model',
        icon: ManageAccountsIcon,
        link: routes.dataExport.url,
        disable: true,
        tooltip:
          'This dashboard is not part of your current package, please contact customer.success@vennflow.com for more information on this feature',
        videoUrl: 'https://www.youtube.com/embed/qIWysveujLg?si=_WLEvE2LK8Cw2osW',
      },
    ],
  },
  {
    name: 'Management',
    items: [
      {
        name: 'Settings',
        icon: SettingsTwoToneIcon,
        link: routes.settings.url,
      },
      {
        name: 'Data Export',
        icon: SettingsTwoToneIcon,
        link: routes.dataExport.url,
      },
      {
        name: 'User Management',
        icon: ManageAccountsIcon,
        link: routes.userManagement.url,
      },
      {
        name: 'Support',
        icon: ContactSupportIcon,
        link: routes.support.url,
      },
    ],
  },
];

export const marketingOnlyMenuItems: MenuItemsProps[] = [
  {
    name: 'General',
    items: [
      {
        name: 'Business Overview',
        icon: RemoveRedEyeTwoToneIcon,
        link: routes.marketingoverview.url,
      },
      {
        name: 'Weekly Marketing Breakdown',
        icon: ManageSearchIcon,
        link: routes.marketingoverviewdetail.url,
      },
      {
        name: 'Paid Marketing Performance',
        icon: ShowChartIcon,
        link: routes.paidmarketingoverview.url,
      },
      {
        name: 'Tiktok and Snapchat Performance',
        icon: QueryStatsIcon,
        link: routes.tiktokDashboard.url,
      },
      {
        name: 'Attribution Model',
        icon: ManageAccountsIcon,
        link: routes.dataExport.url,
        disable: true,
        tooltip:
          'This dashboard is not part of your current package, please contact customer.success@vennflow.com for more information on this feature',
        videoUrl: 'https://www.youtube.com/embed/qIWysveujLg?si=_WLEvE2LK8Cw2osW',
      },
      // {
      //   name: 'Customer Journey',
      //   icon: DonutSmallTwoToneIcon,
      //   link: routes.customerJourney.marketingOnly.url,
      // },
    ],
  },
  {
    name: 'Management',
    items: [
      {
        name: 'Settings',
        icon: SettingsTwoToneIcon,
        link: routes.settings.url,
      },
      {
        name: 'Data Export',
        icon: ManageAccountsIcon,
        link: routes.dataExport.url,
      },
      {
        name: 'User Management',
        icon: ManageAccountsIcon,
        link: routes.userManagement.url,
      },
      {
        name: 'Support',
        icon: ContactSupportIcon,
        link: routes.support.url,
      },
    ],
  },
  {
    name: 'Attribution Model',
    items: [
      {
        name: 'Data Export',
        icon: ManageAccountsIcon,
        link: routes.dataExport.url,
        disable: true,
        tooltip:
          'This dashboard is not part of your current package, please contact customer.success@vennflow.com for more information on this feature',
      },
    ],
  },
];
