import { ListSubheader, List } from '@mui/material';
import { VideoModal } from 'components/videoModal';
import { FC, useState } from 'react';

import { SidebarMenuItem } from './SidebarMenuItemLayout';
import { menuItems, marketingOnlyMenuItems } from './SidebarMenuItems';
import { MenuWrapper, SubMenuWrapper } from './styles';

interface Props {
  maximized?: boolean;
  ismarketing?: boolean;
  companyName?: string;
}

export const SidebarMenu: FC<Props> = ({ maximized, ismarketing, companyName }) => {
  const [videoURL, setVideoURL] = useState('https://www.youtube.com/embed/qIWysveujLg?si=_WLEvE2LK8Cw2osW');
  const [showVideoModal, setShowVideoModal] = useState(false);

  const handleVideoModalClose = () => {
    setShowVideoModal(false);
  };

  const handleMenuSetVideo = (val: string) => {
    setVideoURL(val);
    setShowVideoModal(true);
  };

  const handleClick = (link: string, newTab?: boolean) => {
    if (link) {
      if (newTab) {
        // Open link in a new tab
        window.open(link, '_blank');
      } else {
        // Open link in the same tab
        window.location.href = link;
      }
    }
  };

  // Filter out "Pyramid" and "Ad Spend Analysis" items based on REACT_APP_ENV
  const filteredMenuItems =
    process.env.REACT_APP_ENV !== 'development' && (process.env.REACT_APP_ENV as string) !== 'uat'
      ? menuItems.map(section => ({
          ...section,
          items: section.items.filter(
            item =>
              item.name !== 'Customer Segmentation' &&
              item.name !== 'Ad Spend Analysis' &&
              item.name !== 'Product Sales Analysis' &&
              item.name !== 'Analytic Content' &&
              item.name !== 'Spend Manager' &&
              item.name != 'Pyramid Widget' &&
              item.name != 'New Analytics',
          ),
        }))
      : menuItems;

  return (
    <>
      {!ismarketing
        ? filteredMenuItems.map(section => (
            <MenuWrapper key={section.name}>
              <List
                component="div"
                subheader={
                  maximized ? (
                    <ListSubheader component="div" className="MuiListSubheader-root">
                      {section.name === 'General' ? companyName ?? 'General' : section.name}
                    </ListSubheader>
                  ) : null
                }
              >
                {section.items.map(item => (
                  <SubMenuWrapper key={item.name}>
                    <List>
                      {item.disable ? (
                        <SidebarMenuItem
                          item={item}
                          disabled
                          tooltip={item.tooltip}
                          maximized={maximized}
                          handleVideoSet={() => handleMenuSetVideo(item.videoUrl)}
                        />
                      ) : (
                        <SidebarMenuItem
                          item={item}
                          maximized={maximized}
                          onClick={() => handleClick(item.link, item.newTab)}
                        />
                      )}
                    </List>
                  </SubMenuWrapper>
                ))}
              </List>
            </MenuWrapper>
          ))
        : marketingOnlyMenuItems.map(section => (
            <MenuWrapper key={section.name}>
              <List
                component="div"
                subheader={
                  maximized ? (
                    <ListSubheader component="div" className="MuiListSubheader-root">
                      {section.name === 'General' ? companyName ?? 'General' : section.name}
                    </ListSubheader>
                  ) : null
                }
              >
                {section.items.map(item => (
                  <SubMenuWrapper key={item.name}>
                    <List>
                      {item.disable ? (
                        <SidebarMenuItem
                          item={item}
                          disabled
                          tooltip={item.tooltip}
                          maximized={maximized}
                          handleVideoSet={() => handleMenuSetVideo(item.videoUrl)}
                        />
                      ) : (
                        <SidebarMenuItem item={item} maximized={maximized} />
                      )}
                    </List>
                  </SubMenuWrapper>
                ))}
              </List>
            </MenuWrapper>
          ))}

      {showVideoModal ? <VideoModal videoUrl={videoURL} handleClose={handleVideoModalClose} /> : <></>}
    </>
  );
};
